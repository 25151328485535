import {useEffect, useState, useRef} from "react";
import {useLockFn, useMount, useLocalStorageState} from "ahooks";
import general from "../../general";
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import {useHistory, useLocation} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import URI from "urijs";
import Image from "../../lib/Image";
import {NavLink} from "../../lib/Navigation";
import MobileDetect from "mobile-detect";
import moment from "moment";
import useUrlState from "@ahooksjs/use-url-state";
import {Link} from "react-router-dom";

export default props => {
  const {aplus_queue} = window;
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  const [user, setUser] = useActiveState('user');
  const [config] = useActiveState('config');
  const [agent] = useActiveState('agent');
  const [card, setCard] = useState({});
  const [datas, setDatas] = useState();
  const [vip, setVip] = useState({});
  const mobileDetect = new MobileDetect(navigator.userAgent);
  const [textTips, setTextTips] = useState([]);
  const [init, setInit] = useState(false);
  const [gameAlert,setGameAlert] = useState(false);
  const [notApplicableList,setNotApplicableList] = useState([]);
  const searchRef = useRef();
  const [bonuseither, setBonuseither] = useState('1');

  const [useAppGameList,setUseAppGameList] = useState([]);
  const [useNossAppGameList,setUseNossAppGameList] = useState([]);
  const [useAppAlert,setUseAppAlert] = useState(false);
  const [useAppAlertDate,setUseAppAlertDate] = useLocalStorageState('card-userapp-alert', []);
  const [warnState,setWarnState] = useState(false);
  const [reminderState,setReminderState] = useState(false);

  // useEffect(() => {
  //   !data && setData(datas.find(item => item.active));
  // }, [datas]);

  const getNotApplicableList = ()=>{
    const search_info = searchRef.current?.value
    general.axios.post('/getNotApplicableList',{tab:'mycard',search_info:search_info,pagination:{page:'1',count:'99999'}})
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setNotApplicableList(data.list);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  useEffect(()=>{
    if (alert){
      getNotApplicableList();
      getUseAppGameList('');
    }
  },[gameAlert])

  const getUseAppGameList = (source)=>{
    let search_info = searchRef.current?.value;
    if(source == '') {
      search_info = '';
    }
    general.axios.post('/getUseAppGameList',{tab:'mycard',search_info:search_info,pagination:{page:'1',count:'99999'}})
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setUseAppGameList(data.list);

      if(source == '') {
        setUseNossAppGameList(data.list);
      }

      let asIs = user?.member_id ?? localStorage.getItem('user_id');
      let today = asIs + moment().format('YYYY-MM-DD');
      let notice_day = 'carduserapp_' + today;

      if(!reminderState && useAppAlertDate != notice_day && data.list.length > 0 ) {
        setUseAppAlert(true);
      }
      
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const load = () => {
    Dialog.loading();
    general.axios.post('/getMonthCardInfoV2?noaccess=1')
    .finally(() => {
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status.succeed) {
        Dialog.error(status.error_desc);
        return;
      }

      setCard(data);
      setDatas(data.items);
      setData(data.items.find(item => item.active));
      // console.log(data);

      if(data.explain) {
        let tip = data.explain.split('\r\n');
        setTextTips(tip);
      }
      setInit(true);
      // return;
      
      // setCard(data.money_saving_card);
      // setDatas(data.money_saving_card.items);
      // setData(data.money_saving_card.items.find(item => item.active));
      // setVip(data.vip);
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  // console.log(user);

  // useMount(() => {
  //   load();
  // });

  useEffect(()=>{
    load();
    getNotApplicableList();

    //省钱卡可用游戏
    getUseAppGameList('');
  }, [user]);

  const urlLogin = () => {
    if (params.userid && params.username && params.token) {
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }
  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })
  useMount(urlLogin);

  const [pay, setPay] = useState();
  const [payUrl, setPayUrl] = useState();
  useEffect(() => {
    if (pay) {
      // if (mobileDetect.is('iPhone')) {
      //   let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
      //       .addQuery('username', localStorage.getItem('username'))
      //       .addQuery('token', localStorage.getItem('token'))
      //       .addQuery('grade_id', pay.grade_id)
      //       .addQuery('from', 'react');

      //   setPayUrl(url);
      // } else {
      //   let url = URI('https://sys.wakaifu.com/home/mixPay/' + pay.type)
      //       .addQuery('username', localStorage.getItem('username'))
      //       .addQuery('token', localStorage.getItem('token'))
      //       .addQuery('grade_id', pay.grade_id)
      //       .addQuery('from', 'react');

      //   setPayUrl(url);
      // }
      
      let url = URI(card.pay_url)
          .addQuery('username', localStorage.getItem('username'))
          .addQuery('token', localStorage.getItem('token'))
          .addQuery('grade_id', pay.grade_id)
          .addQuery('from', 'react');

      setPayUrl(url);

      setTimeout(()=>{
        setPay(null)
      }, 8000)
    } else {
      setPayUrl(null);
    }
  }, [pay]);

  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  const cardPay = useLockFn(()=>{
    if(!general.isLogin()) {
      PubSub.publish('alert-login',true)
      return;
    }

    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }
    setPay({type: 'mcard', grade_id: data?.grade_id})

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['BuyMoneySavingCard', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), agent: agent}]
    });
  })

  //金币领取
  const drawGold = () => {
    if (!card?.canBeReceive){
      return
    }
    Dialog.loading();
    const url = parseInt(bonuseither) == 2 ? '/drawGold' : '/receiveMonthCardVoucher'
    const params = parseInt(card.version) == 1 ? {grade_id:card?.grade_id} : {}
    general.axios.post(url, params)
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("领取成功");
          load();
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  // useEffect(()=>{
  //   // window.top.postMessage({ type:'card',success: true}, "*");
  //   window.addEventListener('message', function(event) {
  //     var result = event.data;
  //     if(result?.type) {
  //       if(result.type == 'card') {//重新加载
  //         load();
  //       } 
  //     }
  //   });
  // });

  const updateAgreement = () => {
    let a = warnState == true ? false : (warnState == false ? true : false);
    setWarnState(a);
  }

  const knowUseApp = () => {
    if(warnState) {
      setReminderState(true);
    }

    let asIs = user.member_id ?? localStorage.getItem('user_id');
    let today = asIs + moment().format('YYYY-MM-DD');
    let notice_day = 'carduserapp_' + today;
    setUseAppAlertDate(notice_day)
    setUseAppAlert(false);
  }

  useEffect(()=>{
      props.setOperate(
        <NavLink user={false} to={(()=>{
          let url = URI('https://sys.wakaifu.com/home/boxVarietyRules/save_card')
                .addQuery('origin', 'react')
                .toString();
              return url;
        })()} iframe={{title:'省钱卡规则'}} className="value">规则</NavLink>
      )
    },[props.active])

  return init ? <div><div className="save-card-new">
    <div className="card-index">

      <NavLink className="card-user-info" >
        <div className="bg-text">
          <div className="info-title">
            <div className="icon">
              <Image src={ !general.isLogin() ? '/resources/user/avatar.png' : user.avatar_status === '-1'? '/resources/user/avatar.png' :user.icon_link} alt="个人头像" className="avatar"/>
            </div>
            <div className="info">
              <div className="name">
                {localStorage.getItem('username') ? user?.member_name : '未登录'}
              </div>
              <div className="tips">
                {card?.is_buy ? card?.end_date_time : '尚未开通省钱卡'}
              </div>
            </div>
          </div>
          {/* <div className="tips">
            {card?.is_buy ? card?.end_date_time : '尚未开通省钱卡'}
          </div> */}
        </div>
        <NavLink user={true} to='/card/record' className="record">购买记录{'>'}</NavLink>
      </NavLink>

      {card?.is_buy ?
      <div className="card-bonuseither">
        <div className="title">省钱卡福利（2选1）</div>
        <div className="items">
          {(() => {
            if(card?.canBeReceive) {
              return <>
              <div className={"item " + (bonuseither == 1 ? ' active' : '')} onClick={()=>setBonuseither(1)}>
                <img src="/resources/save/bonuseither_voucher.png" />
                <img src="/resources/save/bonuseither_pitchon.png" className="select pitchon" />
                <img src="/resources/save/bonuseither_unchecked.png" className="select unchecked" />
              </div>
              <div className={"item " + (bonuseither == 2 ? ' active' : '')} onClick={()=>setBonuseither(2)}>
                <img src="/resources/save/bonuseither_coins.png" />
                <img src="/resources/save/bonuseither_pitchon.png" className="select pitchon" />
                <img src="/resources/save/bonuseither_unchecked.png" className="select unchecked" />
              </div>
              </>
            } else {
              if(card?.receiveType == 'voucher') {
                return <div className={"item buyitem"} >
                  <img src="/resources/save/bonuseither_voucher.png" />
                  <img src="/resources/save/bonuseither_pitchon.png" className="select pitchon" />
                </div>
              } else if(card?.receiveType == 'balance') {
                return <div className={"item buyitem"}>
                  <img src="/resources/save/bonuseither_coins.png" />
                  <img src="/resources/save/bonuseither_pitchon.png" className="select pitchon" />
                </div>
              }
            }
          })()}
        </div>
        <div className={"operate " + (card?.canBeReceive  ? ' unclaimeddraw' : ' alreadydraw')} onClick={drawGold}>{card?.canBeReceive ? '立即领取' : '今日已领取'}</div>
      </div>
      : null}

      <div className="service">
        <div className="key">服务选择</div>
        {/* <NavLink user={false} to={(()=>{
          let url = URI('https://sys.wakaifu.com/home/boxVarietyRules/save_card')
                .addQuery('origin', 'react')
                .toString();
              return url;
        })()} iframe={{title:'省钱卡规则'}} className="value">查看详细规则{'>'}</NavLink> */}
      </div>

      <div className="card-trygames">
        <div className="info">
          <div className="title">适用于平台内大部分游戏</div>
          {useNossAppGameList.length > 0 ?
          <div className="desc">
            在玩游戏可用：
            <div className="items">
              {
                useNossAppGameList.slice(0, 5)?.map((item) => {
                  return <div className="item" key={item.id}>
                    <img src={item.game_image.thumb} alt="" />
                  </div>
                })
              }

            </div>
          </div>
          : null}
        </div>
        <div className="operate" onClick={()=>{
            setGameAlert(true)
          }}>查看{'>'}</div>
      </div>

      <div className="card-type">
        {datas?.map(item => <div className={'item' + (item.grade_id === data?.grade_id ? ' active' : '')} onClick={() => setData(item)} >
          {item.hot_title ? <div className="good" style={{backgroundImage: 'url(/resources/save/good.png)'}}>{item.hot_title}</div> : null}
          <div className="info">
            <div className="title">
              {item.name}
            </div>
            <div className="price">
              {item.amount}
            </div>
            <div className="desc2">
              价值{item.old_amount}元
            </div>
          </div>
        </div>)}
      </div>

      {/* <div className="number" style={{backgroundImage:'url(/resources/save/bg.png)',backgroundSize:'100% 100%'}}>
        <img className="top" style={{
          left:(parseInt(data?.usort) == 1) ? '14%' : ((parseInt(data?.usort) == 2) ? '48%' : '82%')
        }} src="resources/save/bg-top.png" alt="" />
        <div>
          <div className="item" dangerouslySetInnerHTML={{__html:data?.buy_tips}}></div>
        </div>
      </div> */}

      <div className="card-numberdesc" style={{backgroundImage:'url(/resources/save/numberdesc-bg.png)',backgroundSize:'100% 100%'}}>
        <img className="top" style={{
          left:(parseInt(data?.usort) == 1) ? '14%' : ((parseInt(data?.usort) == 2) ? '48%' : '82%')
        }} src="resources/save/bg-top.png" alt="" />
        <div>
          <div className="items">
            <div className="item">
              <div className="title">开通立返</div>
              <div className="info">
                <div className="option">
                  <img src="/resources/save/numberdesc.png" />
                  <div className="coin-num">{data?.balanceDesc}金币</div>
                  <div className="des1">立返{data?.balanceDesc}金币</div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="title">每日福利2选1×{data?.grade_id}天</div>
              <div className="info">
                <div className="option">
                  <img src="/resources/save/numberdesc_voucher.png" />
                  <div className="des1">{data?.sumVoucherDesc}</div>
                </div>
                <div className="option numberdesc_or"><img src="/resources/save/numberdesc_or.png" /></div>
                <div className="option">
                  <img src="/resources/save/numberdesc_coins.png" />
                  <div className="des1">{data?.sumBalanceDesc}</div>
                </div>
              </div>
            </div>
          </div>
          {data?.comment ?
          <div className="desc" dangerouslySetInnerHTML={{__html:data?.comment.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r|\n)/g, '$1<br>$2')}}></div>
          : null}
        </div>
      </div>

      {
        card?.is_buy ? <div className="card-btn-display">
          已开通
          <p>({card?.buttonTime})</p>
        </div>
        : <div style={{backgroundImage:'url(/resources/save/开通.png)',backgroundSize:'100% 100%'}} className="card-btn" onClick={cardPay}>
          立即开通
          <img src="/resources/save/ktjhb_tips.png" className="ktjhb_tips" />
          </div>
      }

      {/* <div className="vip-game" onClick={()=>{
            setGameAlert(true)
          }}>查看省钱卡不可用游戏{'>'}
      </div> */}
  
      <div className="imgs">
        <span className="title">0.1折专属特权</span>
        <img src="resources/save/0.1.png" alt="" />
      </div>

      <div className="imgs">
        <span className="title">0.05折专属特权</span>
        <img src="resources/save/0.05.png" alt="" />
      </div>

    </div>
  </div>
  {/***实名认证弹框***/}
  {isCheckAuth?
  <div className="pay-check-auth">
      <div className="auth-index">
        <div className="close" onClick={() => setIsCheckAuth(false)}>
          <img src="/resources/user/close.png" />
        </div>
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>实名认证</h3>
            <p>
            {config.authTopTips}
              {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
            </p>
          </div>
        </div>

        <div className="auth-user-realname">
          <div className="auth-user-input">
            <div className="name">真实姓名</div>
            <input name="nick_name" type="text" placeholder="请输入姓名" autocomplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
          </div>
          <div className="auth-user-input">
            <div className="name">身份证号</div>
            <input name="number" type="text" placeholder="请输入身份证号" autocomplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
          </div>
          <span>
          {config.authBottomTips}
          {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
          </span>
          <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
        </div>
      </div>
    </div> : null
    }
    {/** 提示弹框 **/}
    {
    isCheckAdult ? 
    <div className="pay-check-auth">
      <div className="auth-index adult-index">
        <div className="title">
          <div className="title-img">
            <img src="/resources/user/auth-title.png" />
          </div>
          <div>
            <h3>温馨提示</h3>
            <p>
            {config.adultTips}
              {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
            </p>
          </div>
        </div>
        <div className="auth-user-realname">
          <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
        </div>
      </div>
    </div>
    : null
    }

    <CSSTransition in={!!pay} timeout={250} unmountOnExit={true}>
      <div className="save-pay">
        <div className="mask" onClick={() => setPay(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <object data={payUrl}
            width="100%"
            height={user.is_oversea == '2' ? '370px' : '200px'}
            className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') }
            type="text/html">
          </object>
          {/* <link height={user.is_oversea == '2' ? '370px' : '200px'} className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') } rel="import" href={payUrl} /> */}
          {/* <iframe className={"ordinary " + (user.is_oversea == '2' ? 'overseas' : '') } src={payUrl}/> */}
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={gameAlert} timeout={250} unmountOnExit={true}>
      <div className="game-alert">
        <div className="mask" onClick={() => {
          setGameAlert(false)
        }}/>
        <div className="container">
          <div className="title">以下游戏不可用<img onClick={()=>setGameAlert(false)} className="close" src="resources/community/close.png"></img></div>
          <div className="header">
            <input ref={searchRef} type="text" placeholder='请输入游戏名'/>
            <div className="search" onClick={()=>{
              getNotApplicableList();
              getUseAppGameList('sousuo');
            }}>搜索</div>
          </div>
          {useAppGameList.length === 0 && notApplicableList?.length === 0 ? <div className="empty"><img src="/resources/empty.png" alt=""/>暂无数据</div> : null}
          <div style={{overflow:'scroll',height:'83%'}}>
            
            {useAppGameList.length > 0 ? <>
            <div className="caption">
              <div className="subhead">在玩的游戏可用</div>
            </div>
            <div className="games">
              {useAppGameList?.map((item)=>{
                return (
                  <div key={item.id} className="item">
                    <div className="icon">
                      <img src={item.game_image.thumb} alt="" />
                      <div className="avail-text">可用</div>
                    </div>
                    
                    <div className="gamename">{item.game_name}</div>
                    <div className="desc">{item?.nameRemark}</div>
                  </div>
                )
              })}
            </div></>
            : null}
            {notApplicableList.length > 0 ?
            <div className="caption">
              <div className="subhead">以下游戏暂不可用</div>
              <div className="tips">省钱卡适用于平台大部分游戏，仅以下游戏暂不支持</div>
            </div>
            : null}
            <div className="games" style={{overflow:'scroll'}}>
              {notApplicableList?.map((item)=>{
                return (
                  <div key={item.id} className="item">
                    <img src={item.game_image.thumb} alt="" />
                    <div className="gamename">{item.game_name}</div>
                    <div className="desc">{item.nameRemark}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>

    <CSSTransition in={useAppAlert} timeout={250} unmountOnExit={true}>
    <div className="usable-games">
      <div className="mask" />
      <div className="container">
        <div className="title">您正在玩的游戏省钱卡可用</div>
        <div className="game-lists" style={{overflow:'scroll'}}>
          {
            useAppGameList?.map((item) => {
              return <div className="list" key={item.id} >
                <div className="icon">
                <img src={item.game_image.thumb} alt="" />
                </div>
                <div className="name">{item.game_name}</div>
              </div>
            })
          }
        </div>
        <div className="operate" onClick={() => knowUseApp()}>
          我知道了
        </div>
        <div className="warn-agreement">
          <span className={'agreement ' + (warnState ? ' checked' : '') } onClick={updateAgreement}/>
          <label>下次不再提醒</label>
        </div>
      </div>
    </div>
    </CSSTransition>
  </div> : null;
};