import { Popup } from 'antd-mobile'
import React, { useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons';
import Dialog from '../../lib/Dialog';
import { DownOutlined } from '@ant-design/icons';

export default function GameWelfare(props) {
    
    const [visible,setVisible] = useState(true)

  return (
    <Popup className="version-mask-index"
        visible={visible}
        onMaskClick={() => {
            setVisible(false)
            props.setWelfareMored()
        }}
        bodyStyle={{
          borderTopLeftRadius:'13px',
          borderTopRightRadius:'13px',
          overflow: 'hidden',
          paddingTop:'15px',
          backgroundColor:'#F5F6F8'
        }}>
        {props.game.game_feature_list && <div style={{maxHeight:'75vh',overflowY:'scroll',padding:'15px',paddingTop:'0'}}>
            {props.game.game_feature_list[0].content && <div style={{backgroundColor:'#fff',borderRadius:'13px',padding:'15px',position:'relative'}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <span style={{fontSize:'17px',fontWeight:'500',color:'#383838'}}>上线福利</span>
                    {/* <div onClick={()=>Dialog.alert('温馨提示', '双端互通就是游戏的数据在安卓端和苹果端都能进行角色互通共享的游戏！')}>
                        {parseInt(props.game.is_both) == 1 && <span style={{fontSize:'11px',color:'#54c5cd',border:'1px solid #54c5cd',borderRadius:'4px', margin:'0 5px',padding:'0px 2px'}}>双端互通</span>}
                        {parseInt(props.game.is_both) == 1 && <QuestionCircleOutlined style={{color:'#54c5cd'}}></QuestionCircleOutlined>}
                    </div> */}
                </div>
                <div style={{color:'#666',fontSize:'13px',paddingTop:'10px',overflow:'hidden'}} 
                    dangerouslySetInnerHTML={{
                    __html:props.game.game_feature_list[0].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2')}}>
                </div>
            </div>
            }

            {props.game.game_feature_list[1].content && <div style={{backgroundColor:'#fff',borderRadius:'13px',padding:'15px',marginTop:'15px'}}>
            <div style={{display:'flex',alignItems:'center'}} onClick={()=>Dialog.alert('温馨提示', '充值达到返利标准后，' + (props.game.allow_rebate ? '需要通过app手动申请返利，提交审核后24小时内到账！' : '无需手动申请，游戏一般为次日0点会自动到账！'))}>
                <span style={{fontSize:'17px',fontWeight:'500',color:'#383838'}}>返利</span>
                <span style={{fontSize:'11px',color:'#54c5cd',border:'0.5px solid #54c5cd',borderRadius:'4px', margin:'0 5px',padding:'0px 2px'}}>{props.game.allow_rebate ? '手动返利' : '自动返利'}</span>
                <QuestionCircleOutlined style={{color:'#54c5cd'}}></QuestionCircleOutlined>
            </div>
            <div style={{color:'#666',fontSize:'13px',paddingTop:'10px'}}
                dangerouslySetInnerHTML={{
                    __html:props.game.game_feature_list[1].content.replace(/([^>\r\n\r\n]?)(\r\n\r\n|\n\r|\r)/g, '$1<br>$2')}}></div>
            </div>}

        </div>}
    </Popup>
  )
}
